// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-js": () => import("./../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-apps-raffler-js": () => import("./../src/pages/apps/raffler.js" /* webpackChunkName: "component---src-pages-apps-raffler-js" */),
  "component---src-pages-experiments-cellular-js": () => import("./../src/pages/experiments/cellular.js" /* webpackChunkName: "component---src-pages-experiments-cellular-js" */),
  "component---src-pages-experiments-doodles-js": () => import("./../src/pages/experiments/doodles.js" /* webpackChunkName: "component---src-pages-experiments-doodles-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blogpost-js": () => import("./../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

